import { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { z } from 'zod';
import { recordEvent, setGA4CurrentUser } from '@qb/frontend/analytics/ga4';
import { disconnectSocket } from '@qb/httpRequest/SocketRequest';
import { SERVER_ERROR_MESSAGE } from '@qb/httpRequest/constants';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import { webQueries } from '@qb/httpRequest/webQueries';
import {
  LoginPayload,
  UserInfo,
} from '@/shared/types/controllers/AuthControllerTypes';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { setUser } from '@/src/store/reducers/main';
import { useReduxDispatch } from '@/src/store/store';
import { CreateQuoteIfRecentlyStored } from '../util';

const PASSWORD_MIN_LENGTH = 6;

const schema = z.object({
  email: z
    .string({
      required_error: 'Email is required.',
    })
    .trim()
    .email('Email must be valid.'),
  password: z
    .string({
      required_error: 'Password is required.',
    })
    .trim()
    .min(
      PASSWORD_MIN_LENGTH,
      `Password must be at least ${PASSWORD_MIN_LENGTH} characters.`,
    ),
});

export const useLoginRequest = () => {
  const dispatch = useReduxDispatch();
  const { hideDrawer } = useRightDrawerContext();
  const queryClient = useQueryClient();

  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const {
    setHasAuthenticatedLayoutCookie,
    removeHasAuthenticatedLayoutCookie,
  } = useHasAuthenticatedLayoutCookie();

  const [errorMessage, setErrorMessage] = useState('');

  const submitLoginRequest = async (payload: LoginPayload) => {
    setErrorMessage('');

    const parsedPayload = schema.safeParse(payload);
    if (!parsedPayload.success) {
      const formatted = parsedPayload.error.errors
        .map((err) => err.message)
        .join(', ');
      setErrorMessage(formatted);
      return;
    }

    try {
      // TODO: rewrite using react-query
      const data = await webQueries.auth.login.mutationFn(parsedPayload.data);

      if (data && typeof data.user === 'object') {
        const user = data.user as UserInfo;
        setHasAuthenticatedLayoutCookie();
        dispatch(setUser(user));
        disconnectSocket();

        queryClient.removeQueries();

        const redirectURL = getRedirectURL(
          pathname,
          searchParams.get('headerTextsType'),
          user,
        );
        if (redirectURL) {
          router.push(redirectURL);
        }
        hideDrawer();

        setGA4CurrentUser(user);
        recordEvent('login');

        CreateQuoteIfRecentlyStored((err: Error) => {
          if (err) {
            console.error(err);
          }

          const event = new Event('ReloadQuotebeamWidget');
          window.dispatchEvent(event);

          window?.salesPilotPostMessages?.notifyUserHasLoggedIn();
        });
      } else {
        removeHasAuthenticatedLayoutCookie();
        toast.error(SERVER_ERROR_MESSAGE);
      }
    } catch (e) {
      if (isApiBusinessLogicError(e)) {
        setErrorMessage(e.message);
      }
    }
  };

  return { submitLoginRequest, errorMessage };
};

const getRedirectURL = (
  pathname: string,
  headerTextsType: string | null,
  user: UserInfo,
) => {
  const isOriginallyFromPartPage =
    pathname.startsWith('/part/') || headerTextsType === 'fromPartPage';
  const isOriginallyFromCart =
    pathname.startsWith('/cart') || headerTextsType === 'fromCart';

  if (isOriginallyFromPartPage || isOriginallyFromCart) {
    return '/cart/checkout';
  }
  if (user.company.isSupplier || user.company.isManufacturer) {
    return '/vendorDashboard';
  }
  if (user.company.isBuyer) {
    return '/customerDashboard';
  }

  return null;
};
